import Service from "../utils/Service";

class UserApi {
    login(data, callback) {
        Service.makePostRequest('/profile/login', data, callback);
    }
    
    register(data, callback) {
        Service.makePostRequest('/register', data, callback);
    }

    setFavorite(data, callback) {
        Service.makePostRequest('/product/set-favorite', data, callback);
    }

    createList(data, callback) {
        Service.makePostRequest('/create-shopping-list', data, callback);
    }

    editList(data, callback) {
        Service.makePostRequest('/edit-shopping-list', data, callback);
    }

    deleteList(data, callback) {
        Service.makePostRequest('/delete-shopping-list', data, callback);
    }

    removeFromList(data, callback) {
        Service.makePostRequest('/delete-prod-shopping-list', data, callback);
    }

    editListQty(data, callback) {
        Service.makePostRequest('/edit-prod-shopping-list', data, callback);
    }

    addToList(data, callback) {
        Service.makePostRequest('/add-prod-shopping-list', data, callback);
    }

    saveAsList(data, callback) {
        Service.makePostRequest('/save-order-as-list', data, callback);
    }

    deleteNotif(data, callback) {
        Service.makePostRequest('/read-notification', data, callback);
    }

    editConfigs(data, callback) {
        Service.makePostRequest('/edit-configs', data, callback);
    }

    editAccount(data, callback) {
        Service.makePostRequest('/edit-account', data, callback);
    }

    recoverPassword(data, callback){
        Service.makePostRequest('/login/recuperar/sendemail', data, callback);
    }

    changePassword(data, callback){
        Service.makePostRequest('/alterar-password', data, callback);
    }

    sendFeedback(data, callback){
        Service.makePostRequest('/profile/feedback', data, callback);
    }

    sendFaq(data, callback){
        Service.makePostRequest('/profile/faq', data, callback);
    }

    sendReclamation(data, callback){
        Service.makePostRequest('/profile/reclamation', data, callback);
    }

    logout(callback) {
        Service.makeGetRequest('/logout', callback);
    }

    setCookie(cname, cvalue, exdays) {
        // const d = new Date();
        // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        // let expires = "expires=" + d.toUTCString();
        // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

        localStorage.setItem(cname, cvalue);
    }

    getCookie(cname) {
        // let name = cname + "=";
        // let decodedCookie = decodeURIComponent(document.cookie);
        // let ca = decodedCookie.split(';');
        // for (let i = 0; i < ca.length; i++) {
        //     let c = ca[i];
        //     while (c.charAt(0) === ' ') {
        //         c = c.substring(1);
        //     }
        //     if (c.indexOf(name) === 0) {
        //         return c.substring(name.length, c.length);
        //     }
        // }

        // const ca = `; ${document.cookie}`;
        // const parts = ca.split(`; ${cname}=`);
        // if (parts.length === 2) return parts.pop().split(';').shift();
        // return "";

        return (localStorage.getItem(cname) ? localStorage.getItem(cname) : "");
    }
}

export default new UserApi();