import React from "react";
import Tools from '../../utils/Tools';
import Footer from '../../components/Footer';
import UserApi from "../../api/UserApi";

import '../../styles/Home.scss';
import '../../styles/Profile.scss';

const Header = React.lazy(() => import('../../components/Header'));
const strings = Tools.getStrings();

class RecoverPw extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            result_message: "",
            showPopUp: false
        }
    }

    change(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    submit() {
        let data = { email: this.state.email };
        UserApi.recoverPassword(data, (result) => {
            if(!result.error){
                this.setState({
                    email: "",
                    showPopUp: true,
                    result_message: strings.emailrcv
                }, () => {
                    setTimeout(() => {
                        this.setState({ showPopUp: false, result_message: "" })
                    }, 6000);
                });
            }
            else if(result.status_code==406){
                this.setState({
                    showPopUp: true,
                    result_message: strings.emaildontexist
                }, () => {
                    setTimeout(() => {
                        this.setState({ showPopUp: false, result_message: "" })
                    }, 6000);
                });
            }
        });
    }

    render() {
        let cansubmit = this.state.email !== "";
        return (
            <>
                <Header />
                <div className="register-page">
                    <div className="ordernow-bg" style={{ backgroundImage: 'url(/montalva-web/build/images/background-login.png)', cursor: "default" }}>
                        <div className="content">
                            <div className="container-small" style={{ maxWidth: "430px" }}>
                                <h3 className="register-title white text-center">{strings.recoverpw}</h3>
                                <div className="flex center">
                                    <div className="flex1" style={{ marginRight: "20px" }}>
                                        <p className="size13 white" style={{ marginBottom: "5px" }}>Email</p>
                                        <input name="email" type="email" className="login-input" placeholder={strings.insertemail}
                                            onChange={(e) => this.change(e)}
                                        />
                                    </div>
                                </div>
                                <p className="text-center white" style={{ marginTop: "60px" }}>{strings.notclient}<a className="white" style={{ marginLeft: "5px" }} href="/suporte">{strings.learnregister}</a></p>
                                <div className="text-center" style={{ marginTop: "40px" }}>
                                    <button onClick={() => this.submit()} className="custom-disable" disabled={!cansubmit}
                                        style={{ width: "100%", maxWidth: "300px" }}>{strings.recoverpw}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showPopUp &&
                    <div className="popup-msg">
                        <div className="flex start">
                            <p>{this.state.result_message}</p>
                        </div>
                    </div>
                }
                <Footer />
            </>
        );
    }
}

export default RecoverPw;