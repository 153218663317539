import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import globalState from "./utils/globalState";

import './styles/App.scss';
import RecoverPw from './screens/auth/RecoverPw';

const Home = lazy(() => import('./screens/Home'));
const Campaigns = lazy(() => import('./screens/Campaigns'));
const Categories = lazy(() => import('./screens/Categories'));
const Products = lazy(() => import('./screens/Products'));
const Product = lazy(() => import('./screens/Product'));
const OrderNow = lazy(() => import('./screens/OrderNow'));
const EditOrder = lazy(() => import('./screens/EditOrder'));
const Profile = lazy(() => import('./screens/profile/Profile'));
const Configs = lazy(() => import('./screens/profile/Configs'));
const EditProfile = lazy(() => import('./screens/profile/EditProfile'));
const Clients = lazy(() => import('./screens/profile/Clients'));
const Orders = lazy(() => import('./screens/profile/Orders'));
const Order = lazy(() => import('./screens/profile/Order'));
const History = lazy(() => import('./screens/profile/History'));
const Favorites = lazy(() => import('./screens/profile/Favorites'));
const ShoppingLists = lazy(() => import('./screens/profile/ShoppingLists'));
const FixedOrders = lazy(() => import('./screens/profile/FixedOrders'));
const EditList = lazy(() => import('./screens/profile/EditList'));
const Billing = lazy(() => import('./screens/profile/Billing'));
const BillingHistory = lazy(() => import('./screens/profile/BillingHistory'));
const BillingNotes = lazy(() => import('./screens/profile/BillingNotes'));
const Business = lazy(() => import('./screens/profile/Business'));
const Checkout = lazy(() => import('./screens/Checkout'));
const Support = lazy(() => import('./screens/support/Support'));
const Faq = lazy(() => import('./screens/support/Faq'));
const FaqAnswer = lazy(() => import('./screens/support/FaqAnswer'));
const Complaint = lazy(() => import('./screens/support/Complaint'));
const ComplaintType = lazy(() => import('./screens/support/ComplaintType'));
const Feedback = lazy(() => import('./screens/support/Feedback'));
const FeedbackSuccess = lazy(() => import('./screens/support/FeedbackSuccess'));
const Auth = lazy(() => import('./screens/auth/Auth'));
const Register = lazy(() => import('./screens/auth/Register'));
const RegisterSuccess = lazy(() => import('./screens/auth/RegisterSuccess'));
const Login = lazy(() => import('./screens/auth/Login'));
const RecoverPwEmail = lazy(() => import('./screens/auth/RecoverPwEmail'));


function App() {
  return (
    <Suspense fallback={
      <div className='loading'>
        <img className="loading-img" src="/montalva-web/build/images/loading-image.png" alt="loading-img" />
        <img className="loading-logo" src="/montalva-web/build/images/logo.svg" alt="logo" />
        <div className="bar">
          <div className="bar-inner">
          </div>
        </div>
      </div>
    }>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/campanhas" element={<Campaigns />} />
          <Route path="/ofertas" element={<Products />} />
          {/* <Route path="/ofertas" element={<RegisterSuccess />} /> */}
          <Route path="/produtos" element={<Products />} />
          <Route path="/produto/:product_id/:product_name" element={<Product />} />
          <Route path="/produtos/categoria/:category_id/:category_slug" element={<Categories />} />
          {globalState.loggedIn &&
            <Routes>
              <Route path="/encomendar" element={<OrderNow />} />
              <Route path="/encomendar/editar/:order_id" element={<EditOrder />} />
              <Route path="/encomenda/:order_id" element={<Order />} />
              <Route path="/perfil" element={<Profile />} />
              <Route path="/perfil/configs" element={<Configs />} />
              <Route path="/perfil/editar" element={<EditProfile />} />
              <Route path="/perfil/clientes/:type" element={<Clients />} />
              <Route path="/perfil/encomendas" element={<Orders />} />
              <Route path="/perfil/encomendas/historico" element={<History />} />
              <Route path="/perfil/encomendas/fixas/:id" element={<FixedOrders />} />
              <Route path="/perfil/favoritos" element={<Favorites />} />
              <Route path="/perfil/listas" element={<ShoppingLists />} />
              <Route path="/perfil/lista/:id" element={<EditList />} />
              <Route path="/perfil/faturas" element={<Billing />} />
              <Route path="/perfil/faturas/historico" element={<BillingHistory />} />
              <Route path="/perfil/faturas/notasdecredito" element={<BillingNotes />} />
              <Route path="/perfil/negocio" element={<Business />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/apoio" element={<Support />} />
              <Route path="/apoio/faq" element={<Faq />} />
              <Route path="/apoio/faq/:id" element={<FaqAnswer />} />
              <Route path="/apoio/reclamacao" element={<Complaint />} />
              <Route path="/apoio/reclamacao/:id" element={<ComplaintType />} />
              <Route path="/apoio/feedback" element={<Feedback />} />
              <Route path="/apoio/sucesso" element={<FeedbackSuccess />} />
            </Routes>
          }
          <Route path="/suporte" element={<Auth />} />
          <Route path="/registar" element={<Register />} />
          <Route path="/registar/sucesso" element={<RegisterSuccess />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/recuperar" element={<RecoverPw />} />
          <Route path="/login/recuperar/:token" element={<RecoverPwEmail />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;