const langstrs = {
    en: {
        login: 'Login',
        register: 'Register',
        products: 'Products',
        offers: 'The best offers',
        campaigns: 'Campaigns',
        offerslink: 'All promotional offers',
        support: 'Talk with us',
        about: "About us",
        aboutSubtitle: "See all information about the Montalva/Izidoro Group here",
        more: 'SEE MORE',
        explore: 'Explore our products',
        allprods: 'See full portfolio',
        allOffers: 'See all promotional offers',
        needhelp: 'Need help?',
        talktous: 'Talk to us',
        productscampaign: 'Products in this campaign',
        allproducts: 'All products',
        seemoreprods: 'See more products',
        productsearch: 'Search for a product',
        filters: 'Filters',
        clearfilters: 'Clear filters',
        family: 'Product Family',
        species: 'Type',
        format: 'Format',
        selectvalue: 'Select a value',
        related: 'Related products',
        becomeclient: 'Want to become a client? Make a registration request now.',
        becomeclient2: 'Do you want to become a customer and receive for our products? Make your registration request.',
        registernow: 'Start registration request',
        brand: 'Brand',
        section: 'Gama',
        weight: 'Weight',
        typeweight: 'Type of weight',
        netweight: 'Net weight',
        netweightvariable: 'Avarage net weight',
        ean: 'EAN code',
        daysword: 'days',
        expiration: 'Expiration date',
        numberunits: 'Nº units per ',
        units: 'units',
        unit: 'unit',
        price: 'Price',
        priceconsult: 'Register here to get your price',
        myprofile: 'My profile',
        buy: 'BUY',
        buyhistory: 'Buy through your order history, shopping list and favorites.',
        ordernow: 'Order now',
        fastaccess: 'Fast access',
        mybusiness: 'My Business',
        currentAccount: 'Current Account',
        billing: 'Billing and Payments',
        profile: 'Profile',
        favorites: 'Favorites',
        configs: 'Settings',
        logout: 'Logout',
        mycart: 'My Cart',
        savelist: 'Save as list',
        newfix: 'New fix purchase',
        subtotal: 'Subtotal (no VAT)',
        subtotalword: 'Subtotal: ',
        iva: 'VAT',
        continuebuy: 'Continue shopping',
        gotocheckout: 'Go to checkout',
        addtolist: 'Add product to shopping list',
        priceqty: 'Quantity',
        addtocart: 'Add to cart',
        addtocartandcheckout: 'Add products to cart before going to checkout?',
        addtolist2: 'Add to list',
        savepromo: 'Save',
        apoio: 'Client support',
        orderhistory: 'Orders history',
        reorder: 'Re-order',
        edit: 'Edit',
        seeall: 'See all',
        mostorder: 'Your most purchased products',
        loadmore: 'Load more',
        favoriteprods: 'Favorite products',
        shoppinglists: 'Shopping lists',
        shoppinglist: 'Shopping list',
        shoppinglistsSubtitle: 'Create your shopping lists so you can order in just one click.',
        seelist: 'See list',
        deletelist: 'Delete list',
        qty: 'Qty',
        editorder: 'Edit order',
        order: 'Order',
        downloadbill: 'Download billing',
        listcreated: "Lista criada",
        saveaslist: 'Save as shopping list',
        status: 'Status',
        notifications: 'Notifications',
        services: 'Services',
        hello: 'Hello,',
        accessinfo: 'Access your profile information.',
        mydata: 'My account data',
        orders: 'Orders',
        idioma: 'Language',
        receivenotifs: 'Receive notification alerts by e-mail',
        saveconfigs: 'Save changes',
        accountdata: 'Account data',
        corpname: 'Corporate name',
        address: 'Address',
        nif: 'NIF',
        contactname: 'Contact name',
        phone: 'Phone',
        paymentmethods: 'Payment methods',
        myroutes: 'My routes',
        choosedays: 'Choose the days that you would like to receive your orders.',
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        preferedsch: 'Prefered schedule',
        morning: 'Morning',
        afternoon: 'Afternoon',
        nomatter: "Doesn't matter",
        insertval: 'Insert a value',
        insertaddress: 'Type the address',
        name: 'Name',
        surname: 'Surname',
        datachange: 'Data change request',
        yourdatachange: 'Your data change request has been sent',
        yourdatachange2: 'We will send an email to the address filled in with the validation of the data change request.',
        changeddata: 'Data changed:',
        back: 'Back',
        clients: 'My clients',
        teamclients: "My team's clients",
        seeall1: 'See all',
        contactclient: 'Contact client',
        seeasclient: 'See as client',
        seebusiness: 'See my business',
        inthelast: 'In the last',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        months1: 'meses',
        mostbuy: 'Most bought',
        evolution: 'Evolution',
        mostbuytitle: 'Most bought products',
        clientsearch: 'Search client',
        orderpending: 'Orders in progress',
        see: 'See',
        billingstatus0: 'Unpaid invoice',
        billingstatus1: 'Paid invoice',
        billingstatus2: 'Partially paid',
        billingstatus3: 'Overdue invoice',
        orderfixed: 'Scheduled fixed orders',
        ordersongoing: 'Ongoing orders',
        status0: 'Ongoing',
        status1: 'Paid',
        moreforyou: 'More products for you',
        nofavs: "You didn't add any favorite products yet!",
        emptylist: 'Empty list. Add products.',
        createnewlist: 'Create new list',
        changelistname: 'Change list name',
        buyfromlist: 'Add all products to cart',
        removefromlist: 'Remove product from list',
        addtofavs: 'Add to favorites',
        removefromfavs: 'Remove from favorites',
        emptylist1: 'Empty list. See our products and add them to your list.',
        seedoc: 'See document',
        billing1: 'Outstanding invoices',
        billing2: 'Billing history',
        billing3: 'Credit notes',
        makepayment: 'Make the payment',
        sendpayment: 'Send proof of payment',
        downloadpayment: 'Download document',
        seeorder: 'See order',
        orderby: 'Order by',
        year: 'Year',
        from: 'From',
        to: 'To',
        weekDays: [{ name: 'Sunday', short: 'S', isWeekend: true, }, { name: 'Monday', short: 'M', }, { name: 'Tuesday', short: 'T', }, { name: 'Wednesday', short: 'W', }, { name: 'Thursday', short: 'T', }, { name: 'Friday', short: 'F', }, { name: 'Saturday', short: 'S', isWeekend: true },],
        nextMonth: 'Next month',
        previousMonth: 'Previous month',
        openMonthSelector: 'Open month',
        openYearSelector: 'Open year',
        closeMonthSelector: 'Clear month',
        closeYearSelector: 'Clear year',
        defaultPlaceholder: 'Date',
        confirm: 'Confirm',
        apply: 'Apply',
        productname: 'Product',
        category: 'Category',
        variation: 'Variation',
        value: 'Value',
        action: 'Action',
        savings: 'Savings',
        cartavg: 'Cart average',
        morepotential: 'Articles with greater potential',
        before: 'Before continue',
        before1: 'Before continue...',
        delivery: 'Delivery',
        confirmation: 'Confirmation',
        choosedate: 'Pick your delivery date',
        choosedatedesc: 'Breve descrição sobre o procedimento para anular a compra bem como.',
        pickedday: 'Chosen delivery day *',
        notselected: 'No day selected',
        avancar: 'Continue to checkout',
        goback: 'Go back',
        orderresume: 'Order resume',
        billingdata: 'Billing and delivery data',
        deliverydate: 'Delivery date:',
        finalize: 'Finalize order',
        support2: 'Support',
        clientsupport: 'Client support',
        faq: 'Frequently asked questions',
        faqSubtitle: "Soon you will be able to consult here the answer to the most frequently asked questions related to our shopping platform. Help us build this section and leave the questions you would like answered in the box below. Until then, your doubts will be clarified by our team within 48 working hours.",
        sendfeedback: 'Send feedback',
        complaint: 'Formalize complaint',
        mycommercial: 'My commercial',
        sendemail: 'Send email',
        useful: 'Did you find this answer helpful?',
        picksubject: 'Pick a subject',
        subjectdesc: 'Breve descrição sobre o procedimento para anular a compra bem como.',
        alreadyclient: 'Already have an account? Log in',
        here: ' here',
        fixphone: 'Phone',
        beclient: 'Become client',
        registerreq: 'Registration request',
        startregister: 'Start your registration request',
        registerdesc: 'After submitting your registration request, you will receive a confirmation email and will be contacted by our team within a maximum period of 2 working days.',
        cancel: 'Cancel',
        submitreq: 'Submit request',
        typology: 'Client typology',
        preferedpay: 'Prefered payment method',
        docupload: 'Documents to upload',
        docuploaddesc: 'In order for us to validate your registration, a business certificate and company card are required.',
        attach: 'Attach file',
        clicktofile: 'Click to add',
        uploadeddocs: 'Uploaded documents',
        maxfiles: 'Maximum number of files reached',
        registersubmitted: 'Registration request submitted',
        registerdone: 'O seu pedido de registo foi submetido.',
        registerdesc1: 'We will send an email to the address filled in with the validation of the registration request.',
        registerdesc2: 'While your order is in process, take the opportunity to explore our product portfolio.',
        initsession: 'Log in',
        insertemail: 'Insert your email',
        insertusername: 'Insert your client code',
        insertpassword: 'Insert your password',
        reinsertpassword: 'Reinsert your password',
        passwordchangedsuccessfully: 'Password changed successfully',
        keepsession: 'Keep me logged in',
        forgotpw: 'Forgot your password',
        notclient: 'Not registered yet?',
        learnregister: 'Know how to register',
        recoverpw: 'Recover password',
        nofavorites: "You don't have any favorite products",
        listsaved: 'Shopping list saved!',
        changessaved: 'Changed successfully saved',
        nonotifs: "You don't have any notifications",
        nolists: "You don't have any shopping lists yet",
        notop: "You didn't make any order yet",
        purchase: "Order request done!",
        purchase2: "Your invoice will be available for payment within the next 24 hours. There will be an alert on your profile when the document is available to receive settlement.",
        purchase3: "Your order details:",
        beggining: "Go to homepage",
        orderdone: "Order done",
        cartempty: "You didn't add products to your basket yet.",
        goshop: "See all products",
        confirmfix: "Schedule purchase",
        orderproducts: "Order products",
        freqdelivery: "Delivery frequency",
        purchasecreated: "Fixed purchase successfully created",
        prodcode: "Product SKU",
        orderref: "Order reference",
        orderrefexp: "Field that help you identify your order",
        productadded: "Product added to cart",
        productsadded: "Products added to cart",
        productaddedlist: "Product added to list",
        newnotification: "There is a new document available",
        sendfeedback: "Send feedback",
        startfeedback: "Share with us your experience",
        infofeedback: "We will reply to this contact.",
        feedback: "Write your message",
        productcodelabel: "EAN product code",
        lotelabel: "Lote",
        duedatelabel: "Due date",
        chooseproduct: "Especifique o artigo",
        attachmoreproducts: "Adicionar mais artigos",
        ongoingorder:"There are none ongoing orders",
        pendingorder:"There are none pending orders",
        historyorder:"There are no orders history",
        welcome: "Welcome!",
        fillin: "Please fill in the mandatory fields",
        emailrcv: "Recovery Email sent",
        emaildontexist: "That email is not in our Database",
        passwordmismatch: "The passwords do not match",
        errorEmail: "Error. Plase try again."
    },
    pt: {
        login: 'Iniciar sessão',
        register: 'Registar',
        products: 'Produtos',
        offers: 'As melhores ofertas',
        campaigns: 'Campanhas',
        offerslink: 'Todas as ofertas promocionais',
        support: 'Fale connosco',
        about: "Sobre nós",
        aboutSubtitle: "Consulte todas as informações sobre o Grupo Montalva/Izidoro aqui",
        more: 'SABER MAIS',
        explore: 'Explore os nossos produtos',
        allprods: 'Ver portefólio completo',
        allOffers: 'Ver todas as ofertas promocionais',
        needhelp: 'Precisa de ajuda?',
        talktous: 'Fale connosco',
        productscampaign: 'Produtos nesta campanha',
        allproducts: 'Todos os produtos',
        seemoreprods: 'Ver mais produtos',
        productsearch: 'Pesquisar por produto',
        filters: 'Filtros',
        clearfilters: 'Limpar filtros',
        family: 'Família do produto',
        species: 'Espécie',
        format: 'Formato',
        selectvalue: 'Selecione um valor',
        related: 'Produtos relacionados',
        becomeclient: 'Quer tornar-se cliente? Faça o seu pedido de registo.',
        becomeclient2: 'Quer tornar-se cliente e receber cotação para os nossos produtos? Faça o seu pedido de registo.',
        registernow: 'Iniciar pedido de registo',
        brand: 'Marca',
        section: 'Gama',
        weight: 'Peso',
        typeweight: 'Tipo de peso',
        netweight: 'Peso líquido',
        netweightvariable: 'Peso líquido médio',
        ean: 'Código EAN',
        daysword: 'dias',
        expiration: 'Validade',
        numberunits: 'Nº unidades por ',
        units: 'unidades',
        unit: 'unidade',
        price: 'Preço',
        priceconsult: 'Registe-se aqui para obter o seu preço',
        myprofile: 'O meu perfil',
        buy: 'COMPRAR',
        buyhistory: 'Compre através do seu histórico de encomenda, lista de compras e favoritos.',
        ordernow: 'Encomendar já',
        fastaccess: 'Acesso rápido',
        mybusiness: 'O Meu Negócio',
        currentAccount: 'Conta Corrente',
        billing: 'Faturas e Pagamentos',
        profile: 'Perfil',
        favorites: 'Favoritos',
        configs: 'Configurações',
        logout: 'Terminar sessão',
        mycart: 'O Meu Carrinho',
        savelist: 'Guardar como lista',
        newfix: 'Nova compra fixa',
        subtotal: 'Subtotal (sem IVA)',
        subtotalword: 'Subtotal: ',
        iva: 'IVA',
        continuebuy: 'Continuar a comprar',
        gotocheckout: 'Prosseguir para checkout',
        addtolist: 'Adicionar produto a lista de compras',
        priceqty: 'Quantidade',
        addtocart: 'Adicionar ao carrinho',
        addtocartandcheckout: 'Adicionar produtos ao carrinho antes de ir para o checkout?',
        addtolist2: 'Adicionar a lista',
        savepromo: 'Poupe',
        apoio: 'Apoio ao cliente',
        orderhistory: 'Histórico de encomendas',
        reorder: 'Re-encomendar',
        edit: 'Editar',
        seeall: 'Ver todas',
        mostorder: 'Os seus artigos mais encomendados',
        loadmore: 'Carregar mais',
        favoriteprods: 'Produtos favoritos',
        shoppinglists: 'Listas de compras',
        shoppinglist: 'Lista de compras',
        shoppinglistsSubtitle: 'Crie as suas listas de compras para poder encomendar num só click.',
        seelist: 'Ver lista',
        deletelist: 'Apagar lista',
        qty: 'Qtd',
        editorder: 'Editar encomenda',
        order: 'Encomenda',
        downloadbill: 'Descarregar fatura',
        listcreated: "Lista criada",
        saveaslist: 'Guardar como lista de compras',
        status: 'Estado',
        notifications: 'Notificações',
        services: 'Serviços',
        hello: 'Olá,',
        accessinfo: 'Aceda às suas informações de perfil.',
        mydata: 'Os meus dados de conta',
        orders: 'Encomendas',
        idioma: 'Idioma',
        receivenotifs: 'Receber alertas de notificações por e-mail',
        saveconfigs: 'Guardar alterações',
        accountdata: 'Dados da conta',
        corpname: 'Razão social',
        address: 'Morada',
        nif: 'NIF',
        contactname: 'Nome de contacto',
        phone: 'Telemóvel',
        paymentmethods: 'Métodos de pagamento',
        myroutes: 'As minhas rotas',
        choosedays: 'Escolha os dias em que gostaria de receber as suas encomendas.',
        days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
        preferedsch: 'Preferência de horário',
        morning: 'Manhã',
        afternoon: 'Tarde',
        nomatter: 'Indiferente',
        insertval: 'Insira um valor',
        insertaddress: 'Introduza uma morada',
        name: 'Nome',
        surname: 'Apelido',
        datachange: 'Pedido de alteração de dados',
        yourdatachange: 'O seu pedido de alteração de dados foi enviado',
        yourdatachange2: 'Enviaremos um email para o endereço preenchido com a validação do pedido de alteração de dados.',
        changeddata: 'Dados alterados:',
        back: 'Voltar',
        clients: 'Os meus clientes',
        teamclients: 'Clientes da minha equipa',
        seeall1: 'Ver todos',
        contactclient: 'Contactar cliente',
        seeasclient: 'Ver como cliente',
        seebusiness: 'Ver o meu negócio',
        inthelast: 'Nos últimos',
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        months1: 'meses',
        mostbuy: 'Mais comprados',
        evolution: 'Evolução',
        mostbuytitle: 'Artigos mais comprados',
        clientsearch: 'Pesquisar cliente',
        orderpending: 'Encomendas em curso',
        see: 'Ver',
        billingstatus0: 'Fatura por pagar',
        billingstatus1: 'Fatura paga',
        billingstatus2: 'Paga parcial',
        billingstatus3: 'Fatura vencida',
        orderfixed: 'Compras fixas agendadas',
        ordersongoing: 'Encomendas em curso',
        status0: 'Em curso',
        status1: 'Paga',
        moreforyou: 'Mais produtos para si',
        nofavs: "Ainda não adicionou produtos favoritos!",
        emptylist: 'Lista vazia. Adicione produtos.',
        createnewlist: 'Criar uma nova lista',
        changelistname: 'Alterar nome da lista',
        buyfromlist: 'Adicionar todos os produtos ao carrinho',
        removefromlist: 'Remover produto da lista',
        addtofavs: 'Adicionar aos favoritos',
        removefromfavs: 'Remover dos favoritos',
        emptylist1: 'Lista vazia. Aceda aos nossos produtos e adicione à sua lista.',
        seedoc: 'Ver documento',
        billing1: 'Faturas por liquidar',
        billing2: 'Histórico de faturas',
        billing3: 'Notas de crédito',
        makepayment: 'Efetuar pagamento',
        sendpayment: 'Enviar comprovativo de pagamento',
        downloadpayment: 'Descarregar documento',
        seeorder: 'Ver encomenda',
        orderby: 'Ordenar por',
        year: 'Ano',
        from: 'Desde',
        to: 'Até',
        weekDays: [{ name: 'Domingo', short: 'D', isWeekend: true, }, { name: 'Segunda', short: 'S', }, { name: 'Terça', short: 'T', }, { name: 'Quarta', short: 'Q', }, { name: 'Quinta', short: 'Q', }, { name: 'Sexta', short: 'S', }, { name: 'Sábado', short: 'S', isWeekend: true }],
        nextMonth: 'Próximo mês',
        previousMonth: 'Mês anterior',
        openMonthSelector: 'Abrir meses',
        openYearSelector: 'Abrir anos',
        closeMonthSelector: 'Apagar mês',
        closeYearSelector: 'Apagar ano',
        defaultPlaceholder: 'Data',
        confirm: 'Confirmar',
        apply: 'Aplicar',
        productname: 'Produto',
        category: 'Categoria',
        variation: 'Variação',
        value: 'Valor',
        action: 'Ação',
        savings: 'Poupança',
        cartavg: 'Carrinho médio',
        morepotential: 'Artigos com maior pontencial',
        before: 'Antes de continuar',
        before1: 'Antes de continuar...',
        delivery: 'Entrega',
        confirmation: 'Confirmação',
        choosedate: 'Escolha a sua data de entrega',
        choosedatedesc: 'Breve descrição sobre o procedimento para anular a compra bem como.',
        pickedday: 'Dia definido para a entrega *',
        notselected: 'Nenhum dia selecionado',
        avancar: 'Avançar para o checkout',
        goback: 'Voltar atrás',
        orderresume: 'Resumo da encomenda',
        billingdata: 'Dados de faturação e entrega',
        deliverydate: 'Data de entrega:',
        finalize: 'Finalizar encomenda',
        support2: 'Apoio',
        clientsupport: 'Apoio ao cliente',
        faq: 'Perguntas frequentes',
        faqSubtitle: "Em breve poderá consultar aqui a resposta às questões mais frequentes relacionadas com a nossa plataforma de compras. Ajude-nos a construir esta secção e deixe as questões que gostaria de ver respondidas na caixa abaixo. Até lá as suas dúvidas serão esclarecidas pela nossa equipa em 48h úteis.",
        sendfeedback: 'Enviar feedback',
        complaint: 'Formalizar reclamação',
        mycommercial: 'O meu comercial',
        sendemail: 'Enviar email',
        useful: 'Achou esta resposta útil?',
        picksubject: 'Escolha um assunto',
        subjectdesc: 'Breve descrição sobre o procedimento para anular a compra bem como.',
        alreadyclient: 'Já é cliente? Inicie sessão',
        here: ' aqui',
        fixphone: 'Telefone fixo',
        beclient: 'Torne-se cliente',
        registerreq: 'Pedido de registo',
        startregister: 'Inicie o seu pedido de registo',
        registerdesc: 'Após submeter o seu pedido de registo irá receber um e-mail de confirmação e será contactado pela nossa equipa num prazo máximo de 2 dias úteis.',
        cancel: 'Cancelar',
        submitreq: 'Submeter pedido',
        typology: 'Tipologia de cliente',
        preferedpay: 'Método de pagamento preferencial',
        docupload: 'Upload de documentos',
        docuploaddesc: 'De modo a validarmos o seu registo, serão necessárias uma certidão comercial e cartão da empresa.',
        attach: 'Anexar ficheiro',
        clicktofile: 'Clique para adicionar',
        uploadeddocs: 'Documentos carregados',
        maxfiles: 'Número máximo de anexos atingido',
        registersubmitted: 'Pedido de registo submetido',
        registerdone: 'O seu pedido de registo foi submetido.',
        registerdesc1: 'Enviaremos um email para o endereço preenchido com a validação do pedido de registo.',
        registerdesc2: 'Enquanto o seu pedido se encontra em processamento, aproveite para explorar o nosso portfólio de produtos.',
        initsession: 'Iniciar sessão',
        insertemail: 'Introduza o seu email',
        insertusername: 'Introduza o seu código de cliente',
        insertpassword: 'Introduza a sua password',
        reinsertpassword: 'Reintroduza a sua password',
        passwordchangedsuccessfully: 'Password alterada com sucesso',
        keepsession: 'Manter sessão iniciada',
        forgotpw: 'Esqueceu-se da sua password?',
        notclient: 'Ainda não é cliente?',
        learnregister: 'Saiba como se registar',
        recoverpw: 'Recuperar password',
        nofavorites: 'Ainda não tem produtos favoritos',
        listsaved: 'Lista de compras guardada!',
        changessaved: 'Alterações guardadas com sucesso',
        nonotifs: "Não tem notificações por ler",
        nolists: "Ainda não tem listas de compras",
        notop: "Ainda não tem encomendas feitas",
        purchase: "Pedido de encomenda efetuado!",
        purchase2: "A sua fatura estará disponível para pagamento nas próximas 24h. Receberá um alerta no seu perfil quando o documento estiver disponível para liquidação.",
        purchase3: "Detalhes da sua encomenda:",
        beggining: "Voltar ao início",
        orderdone: "Encomenda efetuada",
        cartempty: "Ainda não adicionou produtos ao carrinho.",
        goshop: "Ver todos os produtos",
        confirmfix: "Agendar compra",
        orderproducts: "Produtos na encomenda",
        freqdelivery: "Frequência de entrega",
        purchasecreated: "Compra fixa criada com sucesso!",
        prodcode: "Código SKU",
        orderref: "Referência da encomenda",
        orderrefexp: "Campo para o ajudar a identificar a sua encomenda",
        productadded: "Produto adicionado ao carrinho",
        productsadded: "Produtos adicionados ao carrinho",
        productaddedlist: "Produto adicionado à lista",
        newnotification: "Tem um novo documento disponível",
        sendfeedback: "Enviar feedback",
        startfeedback: "Partilhe connosco a sua experiência",
        infofeedback: "A nossa resposta será enviada para este contato.",
        feedback: "Escreva a sua mensagem",
        productcodelabel: "Código EAN do artigo",
        lotelabel: "Lote",
        duedatelabel: "Validade",
        chooseproduct: "Especifique o artigo",
        attachmoreproducts: "Adicionar mais artigos",
        ongoingorder:"Não existem encomendas em curso",
        pendingorder:"Não existem encomendas pendentes",
        historyorder:"Não existe histórico de encomendas",
        welcome: "Bem-vindo!",
        fillin: "Por favor preencha os campos obrigatórios",
        emailrcv: "Email de recuperação enviado",
        emaildontexist: "O email introduzido não se encontra na nossa base de dados",
        passwordmismatch: "As passwords não coincidem",
        errorEmail: "Ocorreu um erro. Tente novamente."
    }
};


export default langstrs;
