import React from "react";
import '../styles/App.scss';
import '../styles/Header.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <div className="footer">
                <div className="container flex betwee">
                    <div className="flex1">
                        <img src="/montalva-web/build/images/logo_b.svg" alt="logo" />
                    </div>
                    <div>© 2021<a href="/termos-e-condicoes">Terms and Conditions</a></div>
                </div>
            </div>
        );
    }
}

export default Footer;